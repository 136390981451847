import React, { useEffect, useLayoutEffect, useState } from 'react';

import { IMarkdownRemarkNode } from '@hub-cmd/gatsby';

import { IconCollapsedDocsMenu } from '@hub-fe/common/Icons';
import PopupMenu from '@hub-fe/common/PopupMenu';

export const StandardDocumentationViewer: React.FC<{
    node: IMarkdownRemarkNode;
    tocLabel?: string;
    linkHash?: string;
}> = ({ node, linkHash, tocLabel }) => {
    const [showCollapsedMenu, setShowCollapsedMenu] = useState(false);

    useLayoutEffect(() => {
        if (linkHash && linkHash[0] === '#') {
            const el = document.getElementById(linkHash.substring(1));

            if (el) {
                setTimeout(() => el.scrollIntoView(), 500);
            }
        }
    }, [linkHash]);

    return (
        <div className="standard-documentation-viewer">
            <main id="content">
                <aside>
                    <h1>{tocLabel}</h1>
                    <SectionTableOfContents node={node} />
                </aside>
                <article>
                    <PopupMenu
                        title={<IconCollapsedDocsMenu isOpen={showCollapsedMenu} />}
                        onClickTitle={show => setShowCollapsedMenu(show)}
                    >
                        <SectionTableOfContents node={node} />
                    </PopupMenu>
                    {node.frontmatter.title && <header>{node.frontmatter.title}</header>}
                    <div
                        className="docs-content roomy"
                        dangerouslySetInnerHTML={{ __html: node.html }}
                    />
                </article>
            </main>
        </div>
    );
};

const SectionTableOfContents: React.FC<{ node: IMarkdownRemarkNode }> = ({ node }) => {
    useEffect(() => {
        setActiveHeading(false);
    });

    scrollListener();
    return (
        <ul>
            <div dangerouslySetInnerHTML={{ __html: node.tableOfContents }} />
        </ul>
    );
};

// interactive scrolling logic

function scrollListener() {
    let didScroll = false;

    if (typeof document !== 'undefined') {
        document.body.onscroll = () => (didScroll = true);
        document.body.onresize;
    }

    setInterval(() => {
        if (didScroll) {
            didScroll = false;
            setActiveHeading(true);
        }
    }, 100);
}

function setActiveHeading(setActiveByScroll: boolean) {
    const anchorsInView = getAnchorsInView();

    if (anchorsInView.length === 0) {
        return;
    }

    const contentHeadings = document.querySelectorAll<HTMLAnchorElement>('li');

    const url = location.href.split('/');
    const currentAnchor = url[url.length - 1];
    const home = url.slice(0, url.length - 1).join('/');

    contentHeadings.forEach(heading => {
        const element = heading.querySelectorAll<HTMLAnchorElement>('a')[0];

        if (element) {
            const path = element.href.split('/');
            const page = path[path.length - 1];

            if (setActiveByScroll) {
                if (anchorsInView[0] === page.substring(1)) {
                    element.classList.add('active');
                } else {
                    element.classList.remove('active');
                }
            } else {
                element.classList.toggle(
                    'active',
                    page == currentAnchor || (page == home && currentAnchor == '')
                );
            }
        }
    });
}

function isInViewport(elem: HTMLAnchorElement) {
    const bounding = elem.getBoundingClientRect();

    if (typeof window !== 'undefined') {
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <=
                (window.innerHeight - window.innerHeight * 0.5 ||
                    document.documentElement.clientHeight -
                        document.documentElement.clientHeight * 0.5) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
    return;
}

function getAnchorsInView() {
    const anchors = document.querySelectorAll<HTMLAnchorElement>('h2, h1');
    const anchorsInView: string[] = [];

    anchors.forEach(anchor => {
        if (isInViewport(anchor) && anchor.id) {
            anchorsInView.push(anchor.id);
        }
    });
    return anchorsInView;
}
