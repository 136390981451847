import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { IPageContext } from '@hub-cmd/gatsby';

import { StandardDocumentationViewer } from '@hub-fe/common/StandardDocumentationViewer';
import { siteName } from '@hub-fe/common/siteInfo.variants';
import { DamlHubHelmet, DocsNav } from '@hub-fe/landing/nav';

interface IPageTemplateProps {
    pageContext: IPageContext;
    location?: {
        hash?: string;
        pathname?: string;
    };
}

const DocsPageTemplate: React.FC<IPageTemplateProps> = props => {
    const node = props.pageContext.node;
    const linkHash = props?.location?.hash;

    const data = useStaticQuery(graphql`
        query SearchIndexQuery {
            siteSearchIndex {
                index
            }
        }
    `);

    const searchIndex = data.siteSearchIndex.index;

    return (
        <div className="gatsby-docs">
            <DamlHubHelmet
                title={`${siteName} docs | ${node.frontmatter.title}`}
                meta={[{ name: 'site', content: 'damlhub' }]}
            />
            <DocsNav searchIndex={searchIndex} />
            <StandardDocumentationViewer node={node} linkHash={linkHash} />
        </div>
    );
};

// Needed for Gatsby layout
// ts-unused-exports:disable-next-line
export default DocsPageTemplate;
