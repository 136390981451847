import classNames from 'classnames';
import React, { FunctionComponent, useEffect, useState } from 'react';

import Expander from '@hub-fe/common/Expander';
import { useDismissableElement } from '@hub-fe/common/utilsReact';

export const PopupMenuEntry: FunctionComponent<{
    label: React.ReactElement | string;
    onClick?: () => void;
    noHover?: boolean;
    href?: string;
}> = props => {
    const { href, onClick } = props;

    function handleClick() {
        if (href) {
            pushHistory(href);
        }
        if (onClick) {
            onClick();
        }
    }

    return (
        <div
            className={classNames('popup-menu-entry', { 'no-hover': props.noHover })}
            onClick={handleClick}
        >
            <p className="p2">{props.label}</p>
        </div>
    );
};

/**
 * Append a URL to the current history.
 *
 * Done in an async way so that history is not loaded as part of page load, but
 * instead on-demand as to work better in a Gatsby environment.
 */
async function pushHistory(href: string) {
    const { history } = await import('@hub-fe/common/ControllableBrowserRouter');
    history.push(href);
}

interface IMenuProps {
    disabled?: boolean;
    children?: React.ReactNode;
}

const PopupMenu: FunctionComponent<
    IMenuProps & {
        title: React.ReactElement;
        withExpander?: boolean;
        titleClassname?: string;
        onClickTitle?: (showModal: boolean) => void;
    }
> = props => {
    const { disabled, withExpander, titleClassname, onClickTitle } = props;

    const [showModal, setShowModal] = useState(false);

    const { refDismissable, refControl } = useDismissableElement<HTMLDivElement, HTMLDivElement>(
        () => setShowModal(false)
    );

    useEffect(() => {
        if (onClickTitle) {
            onClickTitle(showModal);
        }
    }, [showModal, onClickTitle]);

    return (
        <div
            className={classNames('popup-menu', { disabled })}
            onClick={() => !disabled && setShowModal(!showModal)}
        >
            <div
                className={classNames(
                    'popup-menu-title',
                    { 'icon-right': withExpander },
                    titleClassname
                )}
                ref={refControl}
                onClick={() => setShowModal(!showModal)}
            >
                {props.title} {withExpander && <Expander expanded={showModal} />}
            </div>
            {showModal && !disabled && (
                <div className="popup-menu-modal" ref={refDismissable}>
                    {props.children}
                </div>
            )}
        </div>
    );
};

export default PopupMenu;
